import {
  Application,
  DetailedProperty,
  GenericResponse,
  GetApplicantResponse,
  Applicant,
} from "@/services/apiTypes";

export function noop(): void {
  // This function should act like a placeholder,
  // so we don't need an implementation.
}

export const monthDiff = (dateFrom: Date) =>
  new Date().getMonth() -
  dateFrom.getMonth() +
  12 * (new Date().getFullYear() - dateFrom.getFullYear());

type CalculateRemainingStepsType = {
  primaryApplicantData: (GetApplicantResponse & GenericResponse) | undefined;
  secondaryApplicantData: (GetApplicantResponse & GenericResponse) | undefined;
  propertyData: DetailedProperty | undefined;
  applicationData: Application | undefined;
};

export const calculateRemainingSteps = ({
  primaryApplicantData,
  secondaryApplicantData,
  propertyData,
  applicationData,
}: CalculateRemainingStepsType): number => {
  let remainingSteps = secondaryApplicantData ? 15 : 9;
  if (
    primaryApplicantData?.is_allowed_to_live_in_uk !== null ||
    primaryApplicantData?.has_fraud_or_crime !== null ||
    primaryApplicantData?.has_criminal_record !== null ||
    primaryApplicantData?.is_bankrupt_or_iva !== null
  )
    remainingSteps--;

  if (primaryApplicantData?.formal_title && primaryApplicantData?.historical_addresses.length)
    remainingSteps--;

  if (primaryApplicantData?.identity_verification?.status === "APPROVED") remainingSteps--;

  if (
    primaryApplicantData?.has_existing_mortgage !== null &&
    primaryApplicantData?.is_currently_renting !== null &&
    (Boolean(Number(primaryApplicantData?.credit_cards_loans)) ||
      primaryApplicantData?.unpaid_outstanding_balance !== null) &&
    primaryApplicantData?.financed_children !== null
  )
    remainingSteps--;

  if (primaryApplicantData?.income_verification?.status === "LINKED") remainingSteps--;

  if (
    // has primary source of income
    primaryApplicantData?.incomes.some((income) => income.is_primary) &&
    // has other source of income (or not)
    primaryApplicantData?.has_other_incomes !== null &&
    ((primaryApplicantData?.has_other_incomes &&
      primaryApplicantData.incomes.find(
        (income) => income.source_type !== "SUMMARY" && !income.is_primary,
      )) ||
      !primaryApplicantData?.has_other_incomes)
  )
    remainingSteps--;

  if (secondaryApplicantData) {
    if (
      secondaryApplicantData?.is_allowed_to_live_in_uk !== null ||
      secondaryApplicantData?.has_fraud_or_crime !== null ||
      secondaryApplicantData?.has_criminal_record !== null ||
      secondaryApplicantData?.is_bankrupt_or_iva !== null
    )
      remainingSteps--;

    if (secondaryApplicantData?.formal_title && secondaryApplicantData?.historical_addresses.length)
      remainingSteps--;

    if (secondaryApplicantData?.identity_verification?.status === "APPROVED") remainingSteps--;

    if (
      secondaryApplicantData?.has_existing_mortgage !== null &&
      secondaryApplicantData?.is_currently_renting !== null &&
      (Boolean(Number(secondaryApplicantData?.credit_cards_loans)) ||
        secondaryApplicantData?.unpaid_outstanding_balance !== null)
    )
      remainingSteps--;

    if (
      // has primary source of income
      secondaryApplicantData.incomes.some((income) => income.is_primary) &&
      // has other source of income (or not)
      secondaryApplicantData?.has_other_incomes !== null &&
      ((secondaryApplicantData?.has_other_incomes &&
        secondaryApplicantData.incomes.find(
          (income) => income.source_type !== "SUMMARY" && !income.is_primary,
        )) ||
        !secondaryApplicantData?.has_other_incomes)
    )
      remainingSteps--;

    if (secondaryApplicantData?.income_verification?.status === "LINKED") remainingSteps--;
  }

  if (propertyData?.address && propertyData?.postcode && propertyData?.using_estate_agent !== null)
    remainingSteps--;

  if (propertyData?.offer_made !== null) remainingSteps--;

  if (propertyData?.lease_type && propertyData?.building_type && propertyData?.number_of_bathrooms)
    remainingSteps--;

  return remainingSteps;
};
