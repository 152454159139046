import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

export const GTM_ID = publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

export const pageview = (url) => {
  window.dataLayer.push({
    event: "pageview",
    page: url,
  });
};
