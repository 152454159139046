import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import api from "@/services/api";
import { noop } from "@/helpers/misc";

type ContextValue = {
  startRefreshing: (options: { delayMs: number }) => void;
  stopRefreshing: () => void;
};

const AuthTokenRefreshContext = createContext<ContextValue>({
  startRefreshing: noop,
  stopRefreshing: noop,
});

export function AuthTokenRefreshProvider({
  children,
  initialExpiresAt,
}: PropsWithChildren<{ initialExpiresAt: Date | null }>) {
  const [isEnabled, setIsEnabled] = useState(false);
  const timeoutRef = useRef<number>();


  const startRefreshing = useCallback((options: { delayMs: number }) => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = window.setTimeout(() => {
      setIsEnabled(true);
    }, options.delayMs);
  }, []);

  const stopRefreshing = useCallback(() => {
    clearTimeout(timeoutRef.current);
    setIsEnabled(false);
  }, []);

  // Use ref for some local state and callbacks so we don't have to pass them
  // to useEffect as dependencies, having it run only on mount.
  const localsRef = useRef({
    initialExpiresAt,
    startRefreshing,
    stopRefreshing,
  });

  localsRef.current = {
    initialExpiresAt,
    startRefreshing,
    stopRefreshing,
  };

  useEffect(() => {
    // Don't automatically refresh token when we are (probably) not logged in.
    if (localsRef.current.initialExpiresAt === null) {
      return;
    }

    // Find out how many ms are there until current token expires.
    const expiresInMs = localsRef.current.initialExpiresAt.getTime() - new Date().getTime();

    let refreshDelay = 0;

    // If token expires within 30s, refresh with no delay.
    if (expiresInMs > 30000) {
      refreshDelay = expiresInMs / 2;
    }

    localsRef.current.startRefreshing({ delayMs: refreshDelay });

    return () => {
      localsRef.current.stopRefreshing();
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      startRefreshing,
      stopRefreshing,
    }),
    [startRefreshing, stopRefreshing],
  );

  return (
    <AuthTokenRefreshContext.Provider value={contextValue}>
      {children}
    </AuthTokenRefreshContext.Provider>
  );
}

export function useAuthTokenRefresh() {
  return useContext(AuthTokenRefreshContext);
}

export default AuthTokenRefreshContext;
